import { useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import './Admin.css';
import React from 'react';

export default function Admin() {
  const [menuItems] = useState([
    { name: 'Questions', route: 'questions' },
    { name: 'Round and round', route: 'round-and-round' },
    { name: 'Closest wins', route: 'closest-wins' },
    { name: 'Reported questions', route: 'reported-questions' },
    { name: 'Player words', route: 'player-words' },
    { name: 'Stats', route: 'stats' },
    { name: 'Design system', route: 'design' },
  ]);

  const [showMenu, setShowMenu] = useState(false);

  const _menu = (isMobile: boolean) => {
    return menuItems.map((item, i) => (
      <NavLink to={'/admin/' + item.route} key={i}>
        {({ isActive }) => (
          <li
            className={isActive ? 'active' : ''}
            onClick={() => (isMobile ? setShowMenu(false) : null)}
          >
            {item.name}
          </li>
        )}
      </NavLink>
    ));
  };

  return (
    <div className="relative">
      {/* DESKTOP */}
      <ul className="admin-menu hidden md:flex gap-1 text-xs mb-5 mx-5">
        {_menu(false)}
      </ul>
      {/* MOBILE */}
      <div
        className="md:hidden fixed top-0 left-0 w-full bg-gray-900 border-b border-gray-400 p-4 z-10 text-right"
        onClick={() => setShowMenu(true)}
      >
        <span className="cursor-pointer p-2">MENU</span>
      </div>
      <div>
        {showMenu && (
          <div
            className="bg-black bg-opacity-50 fixed w-screen h-screen z-10"
            onClick={() => setShowMenu(false)}
          ></div>
        )}
        <div
          className={
            'admin-menu-mobile md:hidden fixed bg-gray-800 h-full z-20 pt-14' +
            (showMenu ? ' show' : ' hide')
          }
        >
          <ul>{_menu(true)}</ul>
        </div>
      </div>
      <div className="p-5 pt-24 md:p-5 md:pt-5">
        <Outlet></Outlet>
      </div>
    </div>
  );
}
