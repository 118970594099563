import React from 'react';
import './QgInput.css';
import QgFieldWrapper from './QgFieldWrapper';

export default function QgInput({
  accept,
  autocomplete,
  autoFocus,
  className,
  id,
  label,
  myRef,
  name,
  onChange,
  onKeyPress,
  placeholder,
  type,
  value,
}: any) {
  return (
    <QgFieldWrapper label={label}>
      <input
        accept={accept}
        autoComplete={autocomplete}
        autoFocus={autoFocus}
        className={'qg-input ' + className}
        id={id}
        name={name}
        onChange={onChange}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        ref={myRef}
        type={type}
        value={value}
      ></input>
    </QgFieldWrapper>
  );
}
