import React from 'react';
import './QgSelect.css';
import QgFieldWrapper from './QgFieldWrapper';

export default function QgSelect({
  className,
  value,
  name,
  label,
  onChange,
  children,
}: any) {
  return (
    <QgFieldWrapper label={label}>
      <select
        className={'qg-select ' + className}
        name={name}
        value={value}
        onChange={onChange}
      >
        {children}
      </select>
    </QgFieldWrapper>
  );
}
