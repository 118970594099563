import { useEffect, useState } from 'react';
import api from '../../../api/Api';
import QgInput from '../../shared/QgInput';
import QgSelect from '../../shared/QgSelect';
import QgButton from '../../shared/qgButton/QgButton';
import QgModal from '../../shared/qgModal/QgModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSkull } from '@fortawesome/free-solid-svg-icons';

interface Word {
  type: string;
  word: string;
}

export default function PlayerWords() {
  const [nouns, setNouns] = useState<Word[]>([]);
  const [adjectives, setAdjectives] = useState<Word[]>([]);
  const [showOnlyAdult, setShowOnlyAdult] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [form, setForm] = useState<any>({
    type: 'noun',
    word: '',
    adult: false,
  });

  useEffect(() => {
    getWords();
  }, []);

  const getWords = () => {
    api
      .getPlayerWords()
      .then((res: any) => {
        setNouns(
          res.data.playerDescriptionWords.filter(
            (word: Word) => word.type === 'noun',
          ),
        );
        setAdjectives(
          res.data.playerDescriptionWords.filter(
            (word: Word) => word.type === 'adjective',
          ),
        );
      })
      .catch((e: any) => console.error(e));
  };

  const handleShowOnlyAdultCheck = ({ target: { checked, name } }: any) => {
    setShowOnlyAdult(checked);
  };

  const addWord = () => {
    const words = form.word.split(',').map((word: string) => word.trim());

    api
      .addPlayerWords({ type: form.type, adult: form.adult ? 1 : 0, words })
      .then(() => {
        getWords();
        setForm({ ...form, word: '' });
      })
      .catch((e) => console.error('error'));
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleFormChange = ({ name, value }: any) => {
    setForm({ ...form, [name]: value });
  };

  return (
    <div>
      <h3 className="text-distinct mb-5">Player words</h3>
      <div className="flex-grow mb-5">
        <input
          className="mr-2"
          type="checkbox"
          checked={showOnlyAdult}
          onChange={handleShowOnlyAdultCheck}
        />
        <label>Show only adult</label>
      </div>
      <div className="text-right mb-5">
        <QgButton onClick={() => setShowModal(true)}>Add word</QgButton>
      </div>

      {/* WORD LISTS */}
      <div className="flex bg-gray-800 p-5">
        <div className="flex-1">
          <b>Adjectives ({adjectives.length})</b>
          {adjectives
            .filter((word: any) =>
              showOnlyAdult ? !!word.adult === showOnlyAdult : true,
            )
            .map((word: any, idx: number) => (
              <div key={idx}>
                {word.word}{' '}
                {word.adult ? (
                  <FontAwesomeIcon className="ml-1" icon={faSkull} />
                ) : (
                  ''
                )}
              </div>
            ))}
        </div>
        <div className="flex-1">
          <b>Nouns ({nouns.length})</b>
          {nouns
            .filter((word: any) =>
              showOnlyAdult ? !!word.adult === showOnlyAdult : true,
            )
            .map((word: any, idx: number) => (
              <div key={idx}>
                {word.word}{' '}
                {word.adult ? (
                  <FontAwesomeIcon className="ml-1" icon={faSkull} />
                ) : (
                  ''
                )}
              </div>
            ))}
        </div>
      </div>

      {/* MODAL */}
      <QgModal show={showModal} onHide={() => handleModalClose()}>
        <h2 className="text-distinct mb-5">Add player word</h2>
        <QgSelect
          label="Type"
          name="type"
          value={form.type}
          onChange={(e: any) => handleFormChange(e.target)}
        >
          <option value="noun">Noun</option>
          <option value="adjective">Adjective</option>
        </QgSelect>
        <QgInput
          label="Word"
          name="word"
          value={form.word}
          onChange={(e: any) => handleFormChange(e.target)}
          onKeyPress={(e: any) => e.key === 'Enter' && addWord()}
        />
        <input
          id="adult"
          className="mr-2"
          type="checkbox"
          name="adult"
          value={form.adult}
          onChange={(e: any) => handleFormChange(e.target)}
        />
        <label htmlFor="adult">Adult</label>
        <div className="text-right">
          <QgButton onClick={() => addWord()}>Add Word</QgButton>
        </div>
      </QgModal>
    </div>
  );
}
