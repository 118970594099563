import React, { useEffect } from 'react';
import './QgModal.css';

export default function QgModal({ children, show, onHide }: any) {
  useEffect(() => {
    const element = document.getElementById('qg-body-wrapper');
    if (element) {
      element.style.overflow = show ? 'hidden' : 'auto';
    }
  }, [show]);
  return (
    <div className={show ? '' : 'hidden'}>
      <div className="qg-modal w-full h-screen fixed top-0 left-0 md:pt-16 p-4 z-10 overflow-y-auto">
        {/* backdrop */}
        <div
          className="w-full h-screen fixed top-0 left-0 bg-black opacity-50"
          onClick={onHide}
        ></div>
        {/* dialog */}
        <div className="bg-white w-full md:w-3/4 max-w-md mx-auto text-gray-700 z-20 p-4 rounded-lg relative animate__animated animate__fadeInDown animate__faster">
          <div
            className="absolute top-3 right-4 cursor-pointer p-2"
            onClick={onHide}
          >
            <i className="fa fa-times text-2xl"></i>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}
