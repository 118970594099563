import React, { useEffect, useState } from 'react';
import api from '../../../api/Api';
import QgButton from '../../shared/qgButton/QgButton';
import QgModal from '../../shared/qgModal/QgModal';
import QgTextArea from '../../shared/QgTextArea';
import QgBadge from '../../shared/QgBadge';

export default function AdminRoundAndRound() {
  const [themes, setThemes] = useState<any>([]);
  const [selectedTheme, setSelectedTheme] = useState<any>(undefined);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [form, setForm] = useState<any>({
    description: '',
    randomizeLetter: false,
  });
  useEffect(() => {
    getThemes();
  }, []);

  const getThemes = () => {
    api
      .getRoundAndRoundThemes()
      .then((res) => {
        setThemes(res.data.themes);
      })
      .catch((e) => console.error(e));
  };

  const save = () => {
    api
      .addRoundAndRoundTheme(form)
      .then((res) => {
        setShowModal(false);
        getThemes();
        setForm({
          description: '',
          randomizeLetter: false,
        });
      })
      .catch((e) => console.log(e));
  };

  const update = () => {
    api
      .editRoundAndRoundTheme(selectedTheme.id, form)
      .then((res) => {
        setShowModal(false);
        getThemes();
        setForm({
          description: '',
          randomizeLetter: false,
        });
      })
      .catch((e) => console.log(e));
  };

  const handleDescriptionChange = (value: string) => {
    setForm({ ...form, description: value });
  };

  const handleRandomizeLetterChange = (event: any) => {
    setForm({ ...form, randomizeLetter: event.target.checked });
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedTheme(undefined);
  };

  const handleThemeClick = (theme: any) => {
    setSelectedTheme(theme);
    setForm({
      description: theme.description,
      randomizeLetter: theme.randomizeLetter,
    });
    setShowModal(true);
  };

  return (
    <div>
      <h3 className="text-distinct mb-5">
        Round and round themes ({themes.length})
      </h3>
      <div className="mb-5 text-right">
        <QgButton onClick={() => setShowModal(true)}>Add theme</QgButton>
      </div>
      <table className="qg-table">
        <tbody>
          {themes.map((theme: any, idx: number) => (
            <tr
              className="p-2 bg-gray-800 border-b"
              key={idx}
              onClick={() => handleThemeClick(theme)}
            >
              <td>
                {theme.description}{' '}
                {theme.randomizeLetter && <span className="font-bold">X</span>}
              </td>
              <td className="text-right">
                {theme.isFree && <QgBadge>FREE</QgBadge>}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <QgModal show={showModal} onHide={() => handleModalClose()}>
        <h2 className="text-distinct mb-5">
          {selectedTheme ? 'Edit' : 'Add'} theme
        </h2>
        <QgTextArea
          label="Theme"
          value={form.description}
          onChange={(e: any) => handleDescriptionChange(e.target.value)}
        ></QgTextArea>
        <input
          className="mr-2"
          type="checkbox"
          name="randomizeLetter"
          checked={form.randomizeLetter}
          onChange={(e: any) => handleRandomizeLetterChange(e)}
        />
        Append random letter
        <div className="text-right">
          <QgButton onClick={() => (selectedTheme ? update() : save())}>
            Save
          </QgButton>
        </div>
      </QgModal>
    </div>
  );
}
