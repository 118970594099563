import { useEffect, useState } from 'react';
import api from '../../../api/Api';
import { Category } from '../../../interfaces/interfaces';
import QgButton from '../../shared/qgButton/QgButton';
import EditQuestionDialog from '../questions/editQuestionDialog/EditQuestionDialog';

export default function ReportedQuestions({
  categories,
}: {
  categories: Category[];
}) {
  const [reportedQuestions, setReportedQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(false);

  useEffect(() => {
    getReportedQuestions();
    getQuestions();
  }, []);

  const getReportedQuestions = () => {
    api
      .getReportedQuestions()
      .then(({ data }) => setReportedQuestions(data.reportedQuestions))
      .catch();
  };

  const getQuestions = () => {
    api
      .getQuestions()
      .then(({ data }) => setQuestions(data.questions))
      .catch();
  };

  const handleMarkAsHandledClick = (id: number) => {
    api
      .editReportedQuestion(id)
      .then(({ data }) => getReportedQuestions())
      .catch();
  };

  const handleEditClick = (questionId: number) => {
    setShowModal(true);
    const q = questions.filter(
      (question: any) => question.id === questionId,
    )[0];
    console.log(q);
    console.log(categories);
    setSelectedQuestion(q);
  };

  return (
    <div>
      <h3 className="text-distinct mb-5">
        Reported questions ({reportedQuestions.length})
      </h3>
      {reportedQuestions?.map((reportedQuestion: any, idx: number) => (
        <div
          key={idx}
          className={
            (reportedQuestion.handeled === 0 ? 'bg-red-900' : 'bg-gray-800') +
            ' mb-5 p-3 md:p-5 rounded-md relative'
          }
        >
          <div className="mb-5 absolute right-3 top-3">
            <div className="text-meta">
              {new Date(reportedQuestion.createdAt).toLocaleDateString('sv')}
              {' - '}
              {new Date(reportedQuestion.createdAt).toLocaleTimeString('sv')}
            </div>
          </div>
          <div className="opacity-50">
            <div className="font-bold text-meta">Reported question:</div>
            <div>{reportedQuestion.question}</div>
            <div className="font-bold mb-5">{reportedQuestion.answer}</div>
          </div>
          <div className="font-bold text-meta">Comment</div>
          <div>{reportedQuestion.comment}</div>

          {reportedQuestion.handeled === 0 && (
            <div className="flex gap-2 mt-5 justify-end">
              <QgButton
                style="secondary"
                onClick={() => handleEditClick(reportedQuestion.questionId)}
              >
                Edit
              </QgButton>
              <QgButton
                onClick={() => handleMarkAsHandledClick(reportedQuestion.id)}
              >
                Mark as handled
              </QgButton>
            </div>
          )}
        </div>
      ))}
      <EditQuestionDialog
        categories={categories}
        question={selectedQuestion}
        showModal={showModal}
        setShowModal={setShowModal}
        onEditSuccess={(event: any) => getQuestions()}
        onAddSuccess={(event: any) => getQuestions()}
      ></EditQuestionDialog>
    </div>
  );
}
