import { useEffect, useState } from 'react';
import api from '../../../api/Api';
import { Category, Question } from '../../../interfaces/interfaces';
import QgBadge from '../../shared/QgBadge';
import QgInput from '../../shared/QgInput';
import QgSelect from '../../shared/QgSelect';
import QgButton from '../../shared/qgButton/QgButton';
import './Questions.css';
import EditQuestionDialog from './editQuestionDialog/EditQuestionDialog';

const filterQuestions = (
  questions: any,
  filterString: string,
  categoryId: number,
  image?: boolean,
  approved?: boolean,
  free?: boolean,
) => {
  return questions.filter(
    (question: any) =>
      (question?.question?.toLowerCase()?.includes(filterString) ||
        question?.answer?.toLowerCase()?.includes(filterString)) &&
      (categoryId === 0 || question?.categoryId === categoryId) &&
      (!image || image === !!question.img) &&
      (!approved || question.approved === 0) &&
      (!free || question.isFreemium === 1),
  );
};

export default function Questions({ categories }: { categories: Category[] }) {
  const [allQuestions, setAllQuestions] = useState<Question[]>([]);
  const [questions, setQuestions] = useState([]);
  const [filterString, setFilterString] = useState('');
  const [categoryId, setCategoryId] = useState(0);
  const [showOnlyImages, setShowOnlyImages] = useState(false);
  const [showOnlyUnapproved, setShowOnlyUnapproved] = useState(false);
  const [showOnlyFree, setShowOnlyFree] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState<
    Question | undefined
  >(undefined);

  useEffect(() => {
    getQuestions();
  }, []);

  const getQuestions = () => {
    api
      .getQuestions()
      .then((res) => {
        if (!res) return;
        setAllQuestions(res.data.questions);
        setQuestions(
          filterQuestions(
            res.data.questions,
            filterString.toLowerCase(),
            categoryId,
            showOnlyImages,
            showOnlyUnapproved,
          ),
        );
      })
      .catch((e) => console.log(e));
  };

  const handleInputChange = (filterString: string) => {
    setFilterString(filterString.toLowerCase());
    setQuestions(
      filterQuestions(
        allQuestions,
        filterString.toLowerCase(),
        categoryId,
        showOnlyImages,
        showOnlyUnapproved,
      ),
    );
  };

  const handleSelectCategoryChange = (categoryId: string) => {
    setCategoryId(Number(categoryId));
    setQuestions(
      filterQuestions(
        allQuestions,
        filterString,
        Number(categoryId),
        showOnlyImages,
        showOnlyUnapproved,
      ),
    );
  };

  const handleAddQuestionClick = () => {
    setSelectedQuestion(undefined);
    setShowModal(true);
  };

  const handleEditModalClick = (question: Question) => {
    setSelectedQuestion(question);
    setShowModal(true);
  };

  const handleShowImageQuestionsCheck = ({
    target: { checked, name },
  }: any) => {
    setShowOnlyImages(checked);
    setQuestions(
      filterQuestions(
        allQuestions,
        filterString,
        Number(categoryId),
        checked,
        showOnlyUnapproved,
        showOnlyFree,
      ),
    );
  };

  const handleShowUnapprovedQuestionsCheck = ({
    target: { checked, name },
  }: any) => {
    setShowOnlyUnapproved(checked);
    setQuestions(
      filterQuestions(
        allQuestions,
        filterString,
        Number(categoryId),
        showOnlyImages,
        checked,
        showOnlyFree,
      ),
    );
  };

  const handleShowOnlyFreeQuestionsCheck = ({
    target: { checked, name },
  }: any) => {
    setShowOnlyFree(checked);
    setQuestions(
      filterQuestions(
        allQuestions,
        filterString,
        Number(categoryId),
        showOnlyImages,
        showOnlyUnapproved,
        checked,
      ),
    );
  };

  return (
    <div>
      <div>
        <h3 className="text-distinct mb-5">Questions</h3>
        <div className="mb-10">
          <div className="flex flex-col md:flex-row gap-2 md:gap-5 w-full flex-grow mb-5 max-w-lg">
            <div className="flex-grow">
              <QgSelect
                className="!mb-0"
                label="Category"
                onChange={(e: any) =>
                  handleSelectCategoryChange(e.target.value)
                }
              >
                <option value="0">All categories</option>
                {categories.map((category, idx: number) => (
                  <option key={idx} value={category.id}>
                    {category.name} ({category.questions})
                  </option>
                ))}
              </QgSelect>
            </div>

            <div className="flex-grow">
              <QgInput
                label="Filter"
                onChange={(e: any) => handleInputChange(e.target.value)}
                className="!mb-0"
              ></QgInput>
            </div>
          </div>
          {/* CHECKBOXES */}
          <div className="flex-grow">
            <input
              id="only-images-checkbox"
              className="mr-2"
              type="checkbox"
              checked={showOnlyImages}
              onChange={handleShowImageQuestionsCheck}
            />
            <label htmlFor="only-images-checkbox" className="select-none">
              Show only image questions
            </label>
          </div>
          <div className="flex-grow">
            <input
              id="only-unapproved-checkbox"
              className="mr-2"
              type="checkbox"
              checked={showOnlyUnapproved}
              onChange={handleShowUnapprovedQuestionsCheck}
            />
            <label htmlFor="only-unapproved-checkbox" className="select-none">
              Show only unapproved questions
            </label>
          </div>
          <div className="flex-grow">
            <input
              id="only-free-checkbox"
              className="mr-2"
              type="checkbox"
              checked={showOnlyFree}
              onChange={handleShowOnlyFreeQuestionsCheck}
            />
            <label htmlFor="only-free-checkbox" className="select-none">
              Show only Free questions
            </label>
          </div>
        </div>
        <div className="flex items-end mb-2">
          <div>{questions.length} questions found</div>
          <div className="flex flex-grow justify-end">
            <QgButton onClick={handleAddQuestionClick}>Add Question</QgButton>
          </div>
        </div>
        {questions.length > 0 && (
          <div>
            {/* DESKTOP - TABLE */}
            <div className="hidden md:block">
              <table className="qg-table">
                <thead>
                  <tr>
                    <th className="text-left">Category</th>
                    <th className="text-left">Question</th>
                    <th className="text-left">Answer</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {questions.map((question: Question, idx: number) => (
                    <tr
                      className={
                        'bg-slate-700' +
                        (!question.approved ? ' unapproved' : '')
                      }
                      key={idx}
                      onClick={() => handleEditModalClick(question)}
                    >
                      {categories
                        .filter(
                          (category) => category.id === question.categoryId,
                        )
                        .map((category, idx) => (
                          <td className="align-text-top" key={idx}>
                            {category.name}
                          </td>
                        ))}
                      <td className="align-text-top text-left">
                        <div>{question.question} </div>
                        {question.img && (
                          <div className="pt-3">
                            <img
                              className="w-20"
                              src={`https://quizmageddon.s3.eu-north-1.amazonaws.com/${question.img}`}
                              alt="alt"
                            ></img>
                            {question.imgCopyright && (
                              <div className="opacity-30 text-meta mt-3">
                                © {question.imgCopyright}
                              </div>
                            )}
                          </div>
                        )}
                      </td>
                      <td className="align-text-top">{question.answer}</td>
                      <td className="align-text-top">{question.tags}</td>
                      <td className="align-text-top">
                        {question.isFreemium ? <QgBadge>FREE</QgBadge> : ''}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* MOBILE - TABLE */}
            <div className="md:hidden">
              {questions.map((question: Question, idx: number) => (
                <div
                  key={idx}
                  className="clickable p-3 bg-gray-800 border-b border-b-solid border-gray-500 relative pt-6"
                  onClick={() => handleEditModalClick(question)}
                >
                  {categories
                    .filter((category) => category.id === question.categoryId)
                    .map((category, idx) => (
                      <div
                        key={idx}
                        className="inline-block px-2 py-1 rounded text-meta absolute right-2 top-2"
                        style={{ backgroundColor: category.color }}
                      >
                        {category.name}
                      </div>
                    ))}
                  <h4 className="mb-0">Question</h4>
                  <div className="mb-3">{question.question}</div>
                  {question.img && (
                    <img
                      className="mb-4"
                      src={`https://quizmageddon.s3.eu-north-1.amazonaws.com/${question.img}`}
                      alt="alt"
                    ></img>
                  )}
                  <h4 className="mb-0">Answer</h4>
                  <div>{question.answer}</div>
                  <div>{question.tags}</div>
                </div>
              ))}
            </div>
          </div>
        )}
        <EditQuestionDialog
          categoryId={categoryId}
          categories={categories}
          question={selectedQuestion}
          showModal={showModal}
          setShowModal={setShowModal}
          onEditSuccess={(event: any) => getQuestions()}
          onAddSuccess={(event: any) => getQuestions()}
        ></EditQuestionDialog>
      </div>
    </div>
  );
}
