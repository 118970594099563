import React from 'react';
import QgFieldWrapper from './QgFieldWrapper';

export default function QgTextArea({
  value,
  name,
  onChange,
  label,
  rows,
  onKeyPress,
}: any) {
  return (
    <QgFieldWrapper label={label}>
      <textarea
        className="text-black px-4 py-3 w-full rounded-md border border-solid border-black"
        name={name}
        value={value}
        onChange={onChange}
        rows={rows}
        onKeyPress={onKeyPress}
      ></textarea>
    </QgFieldWrapper>
  );
}
