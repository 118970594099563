import React from 'react';

export default function QgFieldWrapper({ children, label }: any) {
  return (
    <div className="mb-5">
      <label className="block mb-1 text-meta">{label}</label>
      {children}
    </div>
  );
}
