import React, { useEffect, useState } from 'react';
import api from '../../../api/Api';
import QgButton from '../../shared/qgButton/QgButton';
import QgModal from '../../shared/qgModal/QgModal';
import QgTextArea from '../../shared/QgTextArea';
import QgInput from '../../shared/QgInput';
import QgBadge from '../../shared/QgBadge';

export default function AdminClosestWins() {
  const formInitialValue = {
    question: '',
    answer: 0,
    answerText: '',
    isFree: false,
  };
  const [closestWinsQuestions, setClosestWinsQuestions] = useState<any>([]);
  const [selectedQuestion, setSelectedQuestion] = useState<any>(undefined);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [form, setForm] = useState<any>(formInitialValue);
  useEffect(() => {
    getClosestWinsQuestions();
  }, []);

  const getClosestWinsQuestions = () => {
    api
      .getClosestWinsQuestions()
      .then((res) => {
        setClosestWinsQuestions(res.data.questions);
      })
      .catch((e) => console.error(e));
  };

  const addQuestion = () => {
    api
      .addClosestWinsQuestion(form)
      .then(() => {
        setForm(formInitialValue);
        getClosestWinsQuestions();
      })
      .catch((e) => console.error(e));
  };

  const saveQuestion = () => {
    api
      .editClosestWinsQuestion(form)
      .then(() => {
        handleModalClose();
        getClosestWinsQuestions();
      })
      .catch((e) => console.error(e));
  };

  const handleModalClose = () => {
    setShowModal(false);
    setForm(formInitialValue);
    setSelectedQuestion(undefined);
  };

  const handleFormChange = (e: any) => {
    const { target } = e;
    const { value, name, type, checked } = target;
    const _value = type !== 'checkbox' ? value : checked;
    setForm({ ...form, [name]: _value });
  };

  const handleItemClick = (question: any) => {
    setSelectedQuestion(question);
    setShowModal(true);
    setForm(question);
  };

  return (
    <div>
      <h3 className="text-distinct mb-5">
        Closest wins questions ({closestWinsQuestions.length})
      </h3>
      <div className="mb-5 text-right">
        <QgButton onClick={() => setShowModal(true)}>Add question</QgButton>
      </div>
      <table className="qg-table">
        <thead>
          <tr>
            <th>Question</th>
            <th className="text-right">Answer</th>
          </tr>
        </thead>
        <tbody>
          {closestWinsQuestions.map((question: any, idx: number) => (
            <tr
              className="p-2 bg-gray-800 border-b"
              key={idx}
              onClick={() => handleItemClick(question)}
            >
              <td>{question.question} </td>
              <td className="text-right">{question.answer}</td>
              <td className="text-right">
                {question.isFree ? <QgBadge>Free</QgBadge> : ''}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* MODAL */}
      <QgModal show={showModal} onHide={() => handleModalClose()}>
        <h2 className="text-distinct mb-5">
          {selectedQuestion ? 'Edit' : 'Add'} question
        </h2>
        <QgTextArea
          label="Question"
          name="question"
          value={form.question}
          onChange={(e: any) => handleFormChange(e)}
        ></QgTextArea>
        <QgInput
          label="Answer"
          type="number"
          name="answer"
          value={form.answer}
          onChange={(e: any) => handleFormChange(e)}
          onKeyPress={(e: any) => e.key === 'Enter' && addQuestion()}
        />
        <QgInput
          label="Answer text"
          type="text"
          name="answerText"
          value={form.answerText}
          onChange={(e: any) => handleFormChange(e)}
          onKeyPress={(e: any) => e.key === 'Enter' && addQuestion()}
        />
        <input
          id="isFree"
          className="mr-2"
          type="checkbox"
          name="isFree"
          checked={form.isFree}
          onChange={(e: any) => handleFormChange(e)}
        />
        <label htmlFor="isFree">Is free (no premium required)</label>
        <div className="text-right mt-5">
          <QgButton
            onClick={() => (selectedQuestion ? saveQuestion() : addQuestion())}
          >
            {selectedQuestion ? 'Save' : 'Add question'}
          </QgButton>
        </div>
      </QgModal>
    </div>
  );
}
