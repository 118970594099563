import React from 'react';
import { Category } from '../../../interfaces/interfaces';

export default function Design({ categories }: { categories: Category[] }) {
  return (
    <div>
      <h3 className="text-distinct mb-5">Design system</h3>
      <div className="grid lg:grid-cols-5 gap-3 mb-10">
        {categories.map((category, i) => (
          <div
            key={i}
            className="h-52"
            style={{ backgroundColor: category.color }}
          >
            {category.name}
          </div>
        ))}
      </div>
      <h1 className="text-heading">Heading 1</h1>
      <h2 className="text-distinct">Distinct</h2>
      <p>Paragraph</p>
    </div>
  );
}
