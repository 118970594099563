import axios from 'axios';

let _accessToken = '';

const config = () => {
  return {
    headers: {
      authorization: 'Bearer ' + _accessToken,
    },
  };
};

const api = {
  // CATEGORIES
  getCategories: () => axios.get('/api/categories'),

  // QUESTIONS
  getQuestions: () => axios.get('/api/questions', config()),
  editQuestion: (formData) =>
    axios.put('/api/questions/' + formData.id, formData, config()),
  addQuestion: (formData, img) => _addQuestion(formData, img),
  deleteQuestion: (id) => axios.delete('/api/questions/' + id, config()),

  // ROUND AND ROUND
  getRoundAndRoundThemes: () =>
    axios.get('/api/mini-game/round-and-round', config()),
  addRoundAndRoundTheme: (payload) =>
    axios.post('/api/mini-game/round-and-round', payload, config()),
  editRoundAndRoundTheme: (id, payload) =>
    axios.put(`/api/mini-game/round-and-round/${id}`, payload, config()),

  // CLOSEST WINS
  getClosestWinsQuestions: () =>
    axios.get('/api/mini-game/closest-wins', config()),
  addClosestWinsQuestion: (payload) =>
    axios.post('/api/mini-game/closest-wins', payload, config()),
  editClosestWinsQuestion: (payload) =>
    axios.put('/api/mini-game/closest-wins', payload, config()),

  // PLAYER WORDS
  getPlayerWords: () => axios.get('/api/player-description-words', config()),
  addPlayerWords: (payload) =>
    axios.post('/api/player-description-words', payload, config()),

  // REPORTED QUESTIONS
  getReportedQuestions: () => axios.get('/api/reported-questions', config()),
  editReportedQuestion: (id) =>
    axios.put(`/api/reported-questions/${id}`, config()),

  // AUTH
  login: (formData) => axios.post('/api/login', formData),
  logout: () => _logOut(),
  updateToken: () => _updateToken(),
  setAccessToken: (accessToken) => (_accessToken = accessToken),
  getAccessToken: () => _accessToken,
  logError: (formData) => axios.post('/api/logs/error', formData),
};

const _logOut = () => {
  const refreshToken = localStorage.getItem('refreshToken');
  localStorage.removeItem('refreshToken');
  return axios.post('/api/logout', { refreshToken });
};

const _updateToken = () => {
  const refreshToken = localStorage.getItem('refreshToken');
  return axios.post('/api/token', { token: refreshToken }).then((res) => {
    api.setAccessToken(res.data.accessToken);
    return res;
  });
};

const _addQuestion = (formData, img) => {
  const fd = new FormData();
  Object.keys(formData).forEach((key) => fd.append(key, formData[key]));
  if (img) {
    fd.append('img', img, 'img.jpg');
  }
  // using fetch because axios refused to set content type to multipart
  return fetch('/api/questions', {
    method: 'POST',
    body: fd,
    ...config(),
  });
};

// update token every 10m
setInterval(
  () => {
    api.updateToken();
  },
  10 * 60 * 1000,
);

export default api;
