import { useEffect, useState } from 'react';
import api from '../../../../api/Api';
import { Category, Question } from '../../../../interfaces/interfaces';
import QgInput from '../../../shared/QgInput';
import QgSelect from '../../../shared/QgSelect';
import QgTextArea from '../../../shared/QgTextArea';
import QgButton from '../../../shared/qgButton/QgButton';
import QgModal from '../../../shared/qgModal/QgModal';

export const validateForm = ({ categoryId, question, answer }: any) => {
  if (Number(categoryId) === 0) return false;
  if (question.length < 10) return false;
  if (answer.length === 0) return false;
  return true;
};

const defaultFormValues = {
  id: null,
  question: '',
  answer: '',
  categoryId: 0,
  approved: 0,
  imgCopyright: '',
  isFreemium: 0,
};

// can't keep selected image in state
let addedImage: any;

export default function EditQuestion({
  categories,
  categoryId,
  showModal,
  setShowModal,
  question,
  onEditSuccess,
  onAddSuccess,
}: any) {
  const [formData, setFormData] = useState<Question>(defaultFormValues);
  const [isFormValid, setIsFormValid] = useState(false);
  const [imagePreviewSrc, setImagePreviewSrc] = useState('');

  useEffect(() => {
    setIsFormValid(validateForm(formData));
  }, [formData]);

  useEffect(() => {
    console.log(question);
    setFormData(question || { ...defaultFormValues, categoryId });
  }, [showModal, question]);

  const handleFormChange = ({ name, value }: any) => {
    let _value = name === 'categoryId' ? Number(value) : value;
    setFormData({ ...formData, [name]: _value });
  };

  const handleImageChange = (target: any) => {
    addedImage = target.files[0];
    const imageSrc = URL.createObjectURL(addedImage);
    setImagePreviewSrc(imageSrc);
  };

  const handleClose = () => setShowModal(false);

  const addQuestion = () => {
    api
      .addQuestion(formData, addedImage)
      .then(() => {
        setFormData({
          ...formData,
          question: '',
          answer: '',
          imgCopyright: '',
        });
        addedImage = null;
        setImagePreviewSrc('');
        onAddSuccess('saved');
      })
      .catch((e) => console.error(e));
  };

  const editQuestion = () => {
    api
      .editQuestion(formData)
      .then(() => {
        setFormData({ ...formData, question: '', answer: '' });
        setShowModal(false);
        onEditSuccess('added');
      })
      .catch((e) => console.error(e));
  };

  const deleteQuestion = () => {
    api
      .deleteQuestion(formData.id)
      .then(() => {
        setFormData({ ...formData, question: '', answer: '' });
        setShowModal(false);
        onEditSuccess('deleted');
      })
      .catch((e) => console.error(e));
  };

  const handleApprovedCheck = ({ target: { checked, name } }: any) => {
    setFormData({ ...formData, approved: checked ? 1 : 0 });
  };

  const handleFreeCheck = ({ target: { checked, name } }: any) => {
    setFormData({ ...formData, isFreemium: checked ? 1 : 0 });
  };

  return (
    <div>
      <QgModal show={showModal} onHide={handleClose}>
        <h3 className="text-distinct mb-5">
          {question ? 'Edit ' : 'Submit '}question
        </h3>

        <p className="mb-4">
          Select a category and fill out the form in order to submit a question.
          <i className="text-red-600 fa-solid fa-heart" />
        </p>

        {/* BODY FORM */}
        <div className="mb-5">
          <QgSelect
            label="Category"
            name="categoryId"
            value={formData.categoryId}
            onChange={(e: any) => handleFormChange(e.target)}
          >
            <option value="0">Choose</option>
            {categories?.map((category: Category, idx: number) => (
              <option value={category.id} key={idx}>
                {category.name}
              </option>
            ))}
          </QgSelect>
          <QgTextArea
            label="Question"
            onChange={(e: any) => handleFormChange(e.target)}
            name="question"
            value={formData.question}
            rows={3}
          ></QgTextArea>
          {!question && (
            <QgInput
              label="Image (optional)"
              type="file"
              accept="image/*"
              onChange={(e: any) => handleImageChange(e.target)}
              name="img"
            />
          )}
          <div className="bg-gray-700 mb-5">
            {imagePreviewSrc && (
              <div>
                <img src={imagePreviewSrc} alt="alt" />
              </div>
            )}
            {question?.img && (
              <div>
                <img
                  src={`https://quizmageddon.s3.eu-north-1.amazonaws.com/${question.img}`}
                  alt="alt"
                />
              </div>
            )}
          </div>
          {(imagePreviewSrc || question?.img) && (
            <QgInput
              label="Copyright"
              placeholder="John Doe, CC BY-SA 4.0"
              onChange={(e: any) => handleFormChange(e.target)}
              name="imgCopyright"
              value={formData.imgCopyright || ''}
            ></QgInput>
          )}
          <QgInput
            label="Answer"
            onChange={(e: any) => handleFormChange(e.target)}
            name="answer"
            value={formData.answer}
          ></QgInput>
          <div className="flex-grow">
            <input
              id="approve-checkbox"
              type="checkbox"
              className="mr-2"
              checked={!!formData.approved}
              onChange={handleApprovedCheck}
            />
            <label htmlFor="approve-checkbox" className="select-none">
              Approved
            </label>
          </div>
          <div className="flex-grow">
            <input
              id="free-checkbox"
              type="checkbox"
              className="mr-2"
              checked={!!formData.isFreemium}
              onChange={handleFreeCheck}
            />
            <label htmlFor="free-checkbox" className="select-none">
              Free
            </label>
          </div>
        </div>

        {/* Footer */}
        <div className="flex">
          {question && !question.approved && (
            <div>
              <QgButton onClick={deleteQuestion} style="danger">
                Delete
              </QgButton>
            </div>
          )}
          <div className="flex flex-grow justify-end">
            <QgButton
              onClick={question ? editQuestion : addQuestion}
              disabled={!isFormValid}
            >
              {question ? 'Save' : 'Add'}
            </QgButton>
          </div>
        </div>
      </QgModal>
    </div>
  );
}
