import AppStoreBadge from '../../assets/images/App_Store_Badge.svg';
import GooglePlayBadge from '../../assets/images/Google_Play_Badge.svg';
import bg2 from '../../assets/images/bg2.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

export default function LandingPage() {
  const categories = [
    {
      name: 'Music',
      tailwindBG: 'bg-blue-600',
      icon: 'record-vinyl',
      sampleQuestion: "What was the name of Michael Jackson's monkey?",
    },
    {
      name: 'General',
      tailwindBG: 'bg-yellow-600',
      icon: 'lightbulb',
      sampleQuestion:
        'What year did the Indian ocean Tsunami take place, who caused the death of approximately 225 000 people?',
    },
    {
      name: 'Movies and TV',
      tailwindBG: 'bg-pink-600',
      icon: 'film-simple',
      sampleQuestion: 'What color was the pill that Neo took in the Matrix?',
    },
    {
      name: 'Sports',
      tailwindBG: 'bg-indigo-600',
      icon: 'dumbbell',
      sampleQuestion:
        "What's the maximum score you can achieve in dart with three darts?",
    },
    {
      name: 'Games',
      tailwindBG: 'bg-green-700',
      icon: 'game-console-handheld',
      sampleQuestion:
        'In what game franchise can you find characters such as Bianca, Ken and Zangief?',
    },
    {
      name: 'Technology',
      tailwindBG: 'bg-gray-600',
      icon: 'rocket',
      sampleQuestion:
        'What was the code name for the American-led effort to develop a functional atomic weapon during World War II?',
    },
    {
      name: 'History',
      tailwindBG: 'bg-blue-600',
      icon: 'landmark',
      sampleQuestion: 'What year did the first world war start?',
    },
    {
      name: 'Food and Drinks',
      tailwindBG: 'bg-red-800',
      icon: 'burger-soda',
      sampleQuestion:
        'What drink do you get if you mix whiskey, simple syrup, lemon juice and egg white?',
    },
  ];

  const miniGames = [
    {
      name: 'Round And Round',
      description: `In Round and Round the game will display a random theme, for example HORROR MOVIES. The player has to name a horror movie within 10 seconds and tap the screen in order to pass the turn over to the next player. If time runs out, the player is eliminated. And round and round we go until there's only one player left.`,
      icon: 'fa-duotone fa-rotate',
    },
    {
      name: 'Closest Wins',
      description:
        'In Closest Wins the game will present a question, for example "How long is the golden gate bridge"? Each player submits an answer and the one who is closest wins!',
      icon: 'fa-duotone fa-scale-balanced',
    },
    {
      name: 'Path of the Skull',
      description:
        'Press a combination of buttons that the next player has to remember. If the player remembers the "path" he/she gets to add one more to the sequence. When a player gets it wrong the player is eliminated. And on it goes until there\'s just one player left!',
      icon: 'fa-duotone fa-skull',
    },
    {
      name: 'Trigger Finger',
      description:
        'Trigger Finger is all about reaction time. The player has to hold a button until it turns red, and then release it as fast as possible. The fastest player wins!',
      icon: 'fa-duotone fa-hand-point-up',
    },
  ];

  return (
    <div>
      {/* Background image */}
      <div
        className="fixed top-0 left-0 w-full h-full bg-cover -z-10"
        style={{ backgroundImage: 'url(' + bg2 + ')' }}
      ></div>
      <div className="fixed top-0 left-0 w-full h-full bg-black opacity-70 -z-10"></div>

      {/* section */}
      <div className="relative">
        <div className="min-h-screen flex items-center justify-center flex-col">
          <div className="text-center w-full max-w-xl pb-16">
            <h1 className="!text-5xl text-heading mb-3 p-2 px-4">
              Quizmageddon
            </h1>
            <div>
              <p className="inline-block px-4 mb-5">
                Welcome to Quizmageddon - an awesome quiz game made by quiz
                lovers for quiz lovers! Download now on App Store and Google
                Play or scroll down to read more.
              </p>
              <div className="flex flex-col items-center md:flex-row justify-center gap-2">
                <a
                  href="https://itunes.apple.com/app/apple-store/id320606217"
                  target="_blank"
                  rel="noopener"
                  onClick={(e) => e.preventDefault()}
                >
                  <img
                    decoding="async"
                    src={AppStoreBadge}
                    alt="Download on the App Store"
                    width="160"
                  />
                </a>
                <a
                  href="http://play.google.com/store/?pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                  target="_blank"
                  rel="noopener"
                  onClick={(e) => e.preventDefault()}
                >
                  <img
                    alt="Get it on Google Play"
                    width="160"
                    src={GooglePlayBadge}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 text-2xl flex justify-center w-full pb-8">
          <i className="fa-solid fa-chevron-down animate__animated animate__shakeY animate__slower animate__infinite scroll-down"></i>
        </div>
      </div>

      {/* section */}
      <div className="min-h-screen p-5 pt-8 pb-16 bg-red-900 bg-opacity-90 flex items-center justify-center">
        <div className="max-w-xl">
          <h2 className="text-heading">QuizmaWHAT?!</h2>
          <div className="pt-5">
            <p className="mb-4">
              <b>QUIZMAGEDDON</b> is the ultimate party quizgame - created by
              quizlovers for quizlovers. Inspired by all things good, with a
              touch of <b>DARK HUMOR</b> ala cards against humanity.
              Quizmageddon is meant to be played together with friends on
              location, preferrably <b>AT A PARTY</b>. No fancy remote play
              supported.
            </p>
            <p className="mb-10">
              All you need to play is a <b>smart phone</b> with{' '}
              <b>internet access</b> and some <b>friends</b>. We recommend
              casting the phone to a TV for the best experience.
            </p>
          </div>
        </div>
      </div>

      {/* section */}
      <div className="min-h-screen p-5 pt-8 pb-16 bg-gray-800 bg-opacity-90 flex items-center justify-center">
        <div className="max-w-xl">
          <h2 className="text-heading">Features</h2>
          <ul className="list-disc pl-5 pt-5">
            <li className="mb-5">
              <b>2000+ QUESTIONS</b> spread across <b>10 CATEGORIES</b>. The
              game also features a fair share of <b>IMAGE QUESTIONS</b>. New
              questions are added frequently.
            </li>
            <li className="mb-5">
              4 different <b>MINI GAMES</b> to make things more interesting
              between the rounds.
            </li>
            <li className="mb-5">
              Different <b>PERKS</b> that the players can gain during a round.
              For example <b>DOUBLE UP</b> which grants the player double points
              on a correct answer, or <b>ROBIN HOOD</b> which will make the
              current leader generously donate a point to the player with the
              least amount of points.
            </li>
            <li className="mb-5">
              <b>CUSTOMIZABLE GAMEPLAY</b>: Design your own quiz experience by
              choosing which categories, perks and mini games (among other
              things) that should be enabled.
            </li>
          </ul>
        </div>
      </div>

      {/* section */}
      <div className="min-h-screen p-5 pt-8 pb-16 bg-indigo-700 bg-opacity-90">
        <div>
          <h2 className="text-heading">The categories</h2>
          <p className="mb-10">
            The game features the following categories (presented below with
            sample questions):
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
            {categories.map((category, idx) => (
              <div
                key={idx}
                className={
                  category.tailwindBG + ' h-72 rounded-md p-5 shadow-md'
                }
              >
                <h3 className="text-distinct">
                  <i className={'fa-solid fa-' + category.icon}></i>{' '}
                  {category.name}
                </h3>
                <p>{category.sampleQuestion}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* section */}
      <div className="min-h-screen p-5 pt-8 pb-16 bg-green-700 bg-opacity-90">
        <div>
          <h2 className="text-heading">The minigames</h2>
          <p className="mb-10">
            Quizmageddon offers the following minigames in order to spice things
            up between the question rounds.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            {miniGames.map((miniGame, idx) => (
              <div key={idx} className="bg-gray-700 rounded-md p-5">
                <h3 className="text-distinct">{miniGame.name}</h3>
                <p>{miniGame.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* section */}
      <div className="p-5 bg-gray-800 text-center text-meta">
        <div>
          Developed with{' '}
          <FontAwesomeIcon
            icon={faHeart}
            className="fa-solid fa-heart text-red-600"
          />{' '}
          by Juntti Dev AB
        </div>
        <div>Version {process.env.REACT_APP_VERSION}</div>
      </div>
    </div>
  );
}
