import axios from 'axios';
import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import './App.css';
import api from './api/Api';
import Admin from './components/admin/Admin';
import AdminClosestWins from './components/admin/adminClosestWins/AdminClosestWins';
import AdminRoundAndRound from './components/admin/adminRoundAndRound/AdminRoundAndRound';
import Design from './components/admin/design/Design';
import PlayerWords from './components/admin/playerWords/PlayerWords';
import Questions from './components/admin/questions/Questions';
import ReportedQuestions from './components/admin/reportedQuestions/ReportedQuestions';
import Stats from './components/admin/stats/Stats';
import LandingPage from './components/landingPage/LandingPage';
import Login from './components/login/Login';

function App() {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // check if user has an active token
    api
      .updateToken()
      .then((res) => {
        api.setAccessToken(res?.data.accessToken);
        navigate('admin/questions');
      })
      .catch((e) => {});

    // get categories
    api
      .getCategories()
      .then((res) => {
        setCategories(res.data.categories);
      })
      .catch((e) => console.log(e));

    // remove hardcoded content when JS loads
    const element = document.getElementById('no-js');
    element?.parentNode.removeChild(element);

    axios.interceptors.request.use(
      (config) => {
        setIsLoading(true);
        return config;
      },
      (error) => {
        setIsLoading(false);
        return Promise.reject(error);
      },
    );

    axios.interceptors.response.use(
      (response) => {
        setIsLoading(false);
        return response;
      },
      (error) => {
        setIsLoading(false);
        return Promise.reject(error);
      },
    );
  }, []);

  return (
    <div className="App">
      {isLoading && (
        <div className="bg-black bg-opacity-50 h-full w-full absolute flex items-center justify-center z-50">
          <h3>Loading...</h3>
        </div>
      )}
      <Routes>
        <Route path="login" element={<Login />}></Route>
        <Route exact path="/" element={<LandingPage />}></Route>
        {api.getAccessToken() && (
          <Route path="admin" element={<Admin categories={categories}></Admin>}>
            <Route
              path="questions"
              element={<Questions categories={categories}></Questions>}
            ></Route>
            <Route
              path="round-and-round"
              element={<AdminRoundAndRound></AdminRoundAndRound>}
            ></Route>
            <Route
              path="closest-wins"
              element={<AdminClosestWins></AdminClosestWins>}
            ></Route>
            <Route
              path="reported-questions"
              element={
                <ReportedQuestions categories={categories}></ReportedQuestions>
              }
            ></Route>
            <Route
              path="player-words"
              element={<PlayerWords></PlayerWords>}
            ></Route>
            <Route
              path="design"
              element={<Design categories={categories}></Design>}
            ></Route>
            <Route
              path="stats"
              element={<Stats categories={categories} />}
            ></Route>
          </Route>
        )}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
