import React from 'react';
import './QgButton.css';

export default function QgButton({
  children,
  onClick,
  style,
  className,
  disabled,
}: any) {
  return (
    <button
      type="button"
      className={
        'qg-button py-3 px-6 rounded font-bold ' +
        className +
        (disabled ? ' opacity-50' : '') +
        ' ' +
        (style || 'primary')
      }
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
