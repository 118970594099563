import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import QgInput from '../shared/QgInput';
import QgButton from '../shared/qgButton/QgButton';
import api from './../../api/Api';
import './Login.css';
import QgAlert from '../shared/QgAlert';

export default function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [loginError, setLoginError] = useState('');
  const pwdRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const handleLoginClick = () => {
    setLoginError('');
    api
      .login(formData)
      .then((res) => {
        api.setAccessToken(res.data.accessToken);
        console.log(res);
        localStorage.setItem('refreshToken', res.data.refreshToken);
        navigate('/admin/questions');
      })
      .catch(({ response }) => {
        setLoginError(response?.data);
      });
  };

  const handleFormChange = ({ name, value }: any) => {
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="flex items-center justify-center h-full p-5">
      <div className="w-96">
        <h1 className="mb-3">Login</h1>
        <div>
          <form>
            <QgInput
              id="email"
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              autocomplete="username"
              onChange={(e: any) => handleFormChange(e.target)}
            />
            <QgInput
              id="password"
              label="Password"
              name="password"
              type="password"
              autocomplete="current-password"
              myRef={pwdRef}
              value={formData.password}
              autoFocus={true}
              onChange={(e: any) => handleFormChange(e.target)}
              onKeyPress={(e: any) => e.key === 'Enter' && handleLoginClick()}
            />
          </form>
          {loginError && (
            <QgAlert>{'Something went wrong: ' + loginError}</QgAlert>
          )}
          <QgButton className="mr-2" onClick={handleLoginClick}>
            Login
          </QgButton>
        </div>
      </div>
    </div>
  );
}
